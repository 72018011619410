.portfolio-container {
    padding: 50px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin: 0 auto;
    max-width: 1200px;
    text-align: center;
}

.portfolio-title {
    font-size: 36px;
    color: #333;
    margin-bottom: 40px;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
}

.project-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
    transition: transform 0.3s ease;
}

.project-card:hover {
    transform: translateY(-10px);
}

.project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 20px;
}

.project-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.project-description {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
}

.project-link {
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
}

.project-link:hover {
    text-decoration: underline;
}
