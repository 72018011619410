.about-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjusts spacing between text and image */
  padding: 50px;
  background-color: #f4f4f4;
  border-radius: 8px;
  margin: 0 auto;
  max-width: 800px;
}

.about-text {
  flex: 1; /* Allows the text to take up available space */
}

.about-title {
  font-size: 36px;
  margin-bottom: 20px;
  color: #333;
}

.about-description {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 20px;
}

.about-link {
  color: #007bff;
  text-decoration: none;
}

.about-link:hover {
  text-decoration: underline;
}

.profile-pic {
  width: 200px; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
}
