.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contact-heading {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .contact-form label {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .contact-input, .contact-textarea {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .contact-textarea {
    height: 150px;
    resize: vertical;
  }
  
  .contact-button {
    padding: 0.5rem 1rem;
    background-color: #242424;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .contact-button:hover {
    background-color: #333;
  }
  