.footer-container {
  background-color: #242424;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  flex-direction: column; /* Changed to column for vertical alignment */
  align-items: center; /* Centering all items horizontally */
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
}

.social-icons {
  display: flex;
  justify-content: center; /* Centering icons horizontally */
  align-items: center;
  width: 240px;
  margin-top: 10px; /* Space between social icons and other content */
}

.social-icon-link {
  color: #fff;
  font-size: 24px;
  margin: 0 10px;
  transition: color 0.3s;
}

.social-icon-link:hover {
  color: blue;
}

.footer-logo {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  margin-bottom: 16px; /* Space below the logo */
}

.website-rights {
  color: #fff;
  margin-top: 16px;
  text-align: center; /* Ensuring the text is centered */
}

@media screen and (max-width: 820px) {
  .social-media-wrap {
    flex-direction: column;
    align-items: center;
  }

  .social-icons {
    margin-top: 10px;
  }
}
